<template>
    <div class="home">
        <div v-if="data">
            <HelloWorld msg="รวมสินค้าป้ายยา" :data="data" :count="count" />
           <!--  <div class="myfoot">
                <van-pagination v-model="currentPage" :total-items="itemsCount['total-items']" prev-text="Prev" next-text="Next" :items-per-page="itemsCount['items-per-page']" :page-count="itemsCount['page-count']" />
            </div> -->
        </div>
    </div>
</template>
<script>
import { query, collection, getDocs, orderBy, deleteField } from "firebase/firestore"
import db from './../firebase-config'
import HelloWorld from '@/components/HelloWorld.vue'
// import DATA from './../assets/data.json'


export default {
    name: 'HomeView',
    components: {
        HelloWorld
    },
    data() {
        return {
            search: '',
            count: 0,
            data: [],
            currentPage: 1,
            is_loading: false,
            itemsCount: {
                "items-per-page": 0,
                "page-count": 6,
                "total-items": 8

            }
        }

    },
    methods: {

        async getAllItems() {
            const querySnap = await getDocs(query(collection(db, 'items'), 
              orderBy('createAt', 'desc'), 
           
              ));

            querySnap.forEach((doc) => {


                var u = doc.data()
                u.id = doc.id
                this.data.push(u)

            
                
                
                
            })
            this.count = this.data.length

        },



    },
    watch: {
        // currentPage() {
        //     this.is_loading = true
        //     this.data = []
        //     // this.data = DATA.filter((k, i) => { return i >= Number(this.currentPage * 6 - 6) && i < this.currentPage * 6 })
        // }
    },
    created() {
        this.getAllItems()
    },


}
</script>
<style scoped>
.myfoot {

    background: #e1e1e1;
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0;
    right: 0;
}

.van-pagination {

    padding: 10px;
}
</style>