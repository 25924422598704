// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBoa8LFAJiLSl0fMk78AGQlselOZai8Sbw",
  authDomain: "affiliate-d4391.firebaseapp.com",
  projectId: "affiliate-d4391",
  storageBucket: "affiliate-d4391.appspot.com",
  messagingSenderId: "314645949238",
  appId: "1:314645949238:web:93e9739e15357d7ff42197",
  measurementId: "G-5ZTVKXCD95"
};

initializeApp(firebaseConfig)
// Initialize Firebase

// const analytics = getAnalytics(app);

const db = getFirestore()
export default db

