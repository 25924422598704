<template>
    <div class="hello">
        <div style="padding: 10px 15px;" class="lead">
            <h3 style="font-family: 'IBM Plex Sans Thai', sans-serif!important;">{{msg}}</h3>
            <p class="sub-title">หากปุ่มไหนกดไม่ได้ แสดงว่าลิ้งที่นั่นไม่มีนะครับ ผมจะพยายามใส่ให้เยอะที่สุดนะครับหากสินค้าไหนไม่มีสอบถามได้เลยครับ สินค้าทั้งหมด {{count}} ชิ้น</p>
        </div>
        <van-swipe-cell v-for="(item,i) in data" :key="i">
            <van-card currency="฿" :tag="item.is_hot == true ? 'HOT': ''" :title="item.name" :desc="item.desc" :price="item.price" :thumb="item.image">
                <template #num>
                    <van-button style="margin-right: 12px;" :class="item.market+'-button' " :url="item.url">
                        {{item.market == "other" ?'Other' :''}}
                    </van-button>
                </template>
            </van-card>
            <template #right>
                <van-button square text="Delete" type="danger" class="delete-button" style="height:100%!important;" @click="deleteCard(item.id)" />
            </template>
        </van-swipe-cell>
    </div>
</template>
<script>
import { SwipeCell, showDialog, showConfirmDialog } from 'vant';

import { getDocs, doc, deleteDoc } from 'firebase/firestore'
import db from './../firebase-config'



export default {
    name: 'HelloWorld',
    props: {
        msg: String,
        data: null,
        count: null
    },
    data() {
        return {
            name: '',
            capital: '',


        }
    },
    created() {
     
    },
    methods: {

        async dropCapital(e) {
           
            await deleteDoc(doc(db, 'items', e))
        },
        deleteCard(e) {

            showConfirmDialog({
                    title: 'ลบจริงนะ',
                    message: 'จะลบไปเลยนะ แน่ใจ!',

                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: "ยกเลิก"
                })
                .then(() => {
                    this.dropCapital(e)
                })
                .catch(() => {




                });









        },
        async getAllItems() {
            // var users = []
            // const q = query(collection(db, 'items'), where('name', '>', 'เตาปิ้งย่าง ไม้สวยๆลดกระหน่ำ ถูกมาก'))
            // const querySnaps = await getDocs(q);

            // querySnaps.forEach((docs) => {
            //     users.push(docs.data())
            // })


            // console.log(users);






            // const querySnap = await getDocs(query(collection(db, 'items'), orderBy('createAt', 'desc')));
            // querySnap.forEach((doc) => {
            //     this.items.push(doc.data())

            //     console.log(doc.data());



            // })

            // this.count = this.items.length
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.van-card__footer .van-button {
    margin-right: 25px;
}

.van-card {
    border-bottom: 1px solid #e5e5e594;
    border-top: 1px solid #e5e5e5bd;
}

.lazada-button {
    color: #ffffff;
    background: url(../assets/laz-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #0f146c;
}

.shopee-button {
    color: #ffffff;
    background: url(../assets/shopee-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.van-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 20px;
    margin: 0;
    padding: 0;
    font-size: 8pt;
    line-height: var(--van-button-default-line-height);
    text-align: center;
    border-radius: var(--van-button-radius);
    cursor: pointer;
    transition: opacity var(--van-duration-fast);
    -webkit-appearance: none;
    -webkit-font-smoothing: auto;
    width: 43px;
}

.van-tag {
    background: #fa1953;
}

.sub-title {
    padding: 0px 5px;
    margin-top: -5px;
    color: #546c84;

    line-height: 10pt;

    font-size: 8pt;
}

.hello {
    padding-bottom: 80px;
}

h1 {
    text-align: left;
}

h3 {
    margin: 0px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.van-card[data-v-469af010] {
    border-bottom: 1px solid #e5e5e594;
    border-top: 1px solid #e5e5e5bd;
    transition: all .2s;
}

.ease {
    left:1000px;

}
.van-swipe-cell__right {
    position: absolute;
    top: 0;
    height: 100%;
}
</style>