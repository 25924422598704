import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { Button, Card, Pagination, Field, CellGroup, Form, Picker, Popup, Grid, GridItem, Image as VanImage, SwipeCell  } from 'vant';
import 'vant/lib/index.css';



createApp(App).use(router)
    .use(Button)
    .use(Card)
    .use(Popup)
    .use(Pagination)
    .use(Field).use(Form)
    .use(CellGroup).use(Picker)
    .use(Grid)
    .use(GridItem)
    .use(VanImage)
    .use(SwipeCell)
    .mount('#app')