<template>
  <nav>
  <router-link to="/">Home</router-link> |
    <router-link to="/add">About</router-link>

  </nav>
  <router-view/>
</template>

<style>
.myfoot {

    background: #e1e1e1;
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0;
    right: 0;
}

#app {

    font-family: 'IBM Plex Sans Thai', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #2c3e50;
    margin-top: 10px;

}




nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
